import React,{useState} from 'react';
import mail from './mail.png';
import phone from './phone.png';
import './Form.css';

// const IT Incident = {
//     'Laptop/Desktop Issue': {
//       'Category': [
//         'Laptop/Desktop Issue',
//         'Account Lock',
//         'Other ( Please Specify )',
//         'Performance Issue',
//         'Unable To Login'
//       ]
//     },
//     'Hardware Issue': {
//       'Category': [
//         'Power Issue',
//         'Unable To Start'
//       ]
//     },
//     'Email MailBox Issue': {
//       'Category': [
//         'Mail Searching / Indexing',
//         'MailBox Full / Archive',
//         'Other ( Please Specify )',
//         'Unable To Send / Receive'
//       ]
//     },
//     'File / Share Folder Issue': {
//       'Category': [
//         'Copy / Paste Issue',
//         'Drive Not Accessible',
//         'Files Missing',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Outlook / Webmail Issue': {
//       'Category': [
//         'Outlook Is Slow',
//         'Unable To Access Webmail',
//         'Outlook PST',
//         'Other ( Please Specify )'
//       ]
//     },
//     'VPN Issues': {
//       'Category': [
//         'Unable To Connect',
//         'VPN Connected but Unable to access IT Service Request',
//         'Disconnecting Frequently',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Internet Issue': {
//       'Category': [
//         'Website Blocked / Not Working',
//         'Internet Is Slow',
//         'No Internet',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Printer / Scanner Issues': {
//       'Category': [
//         'Paper Jam / Toner Empty',
//         'Scanner not working',
//         'Printer not working',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Conference / Teams Meeting': {
//       'Category': [
//         'Unable To Connect',
//         'Voice / Video Issue',
//         'Meeting Link not working',
//         'Other ( Please Specify )'
//       ]
//     },
//     'User Software Issues': {
//       'Category': [
//         'Tally not working',
//         'Microsoft Teams not working',
//         'Google Meeting not working',
//         'MS not working',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Telephone Issues': {
//       'Category': [
//         'Phone Lines Down',
//         'Phone Device Issue',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Password Issues': {
//       'Category': [
//         'Laptop / Desktop Login Password',
//         'Mail Password',
//         'Other ( Please Specify )'
//       ]
//     },
//     'Biometric Issues': {
//       'Category': [
//         'Unable to Clock IN / OUT',
//         'Clock IN / OUT is not updated in attendance portal',
//         'Other ( Please Specify )'
//       ]
//     },
//     'For IT Infra Network Team': {
//       'Category': [
//         'Network Policy Update',
//         'Network Device Down',
//         'Branch Network Down',
//         'Application Blocked',
//         'Internet Slowness Issue'
//       ]
//     },
//     'Other': {
//       'Category': [
//         'IT IT Incident Management',
//         'Other'
//       ]
//     }
// };
// const Service = {
//   'CCTV Requests': {
//     'Category': [
//       'Download and share CCTV Footage File',
//       'View only access to CCTV Footage',
//       'Other ( Please Specify )',
//     ]
//   },
//   'Data Backup and Retrieval': {
//     'Category': [
//       'For Self',
//       'Exit User',
//       'Existing User',
//     ]
//   },
//   'Email Requests': {
//     'Category': [
//       'Share Email Data of other Users',
//       'Send Email to external domains',
//       'Online Archrival of Email',
//       'Own Email Data Backup',
//       'Increase Incoming or Outgoing E-Mail Size',
//       'Create New Group Email ID',
//       'ID Block / Unblock',
//     ]
//   },
//   'EndPoint Request': {
//     'Category': [
//       'Repalce Or Upgrade Laptop / Desktop',
//       'Other ( Please Specify )'
//     ]
//   },
//   'External Accessories ': {
//     'Category': [
//       'External Wired Keyboard / Mouse',
//       'External Wireless Keyboard / Mouse',
//       'Install External Device',
//       'Other ( Please Specify )'
//     ]
//   },
//   'Company - IT Infra Setup': {
//     'Category': [
//       'Complete IT Infra Setup',
//       'Partial IT Infra Setup',
//       'Other ( Please Specify )'
//     ]
//   },
//   'Internet Access': {
//     'Category': [
//       'Allow Internet Access to Internet Website',
//       'Other ( Please Specify )'
//     ]
//   },
//   'IT Internal - Activities': {
//     'Category': [
//       'Server Access',
//       'IP WhiteListed',
//       'Increase Server Disk Space',
//       'Allow IP Scanning',
//       'Other ( Please Specify )'
//     ]
//   },
//   'Passwor Request': {
//     'Category': [
//       'System Login Password',
//       'Other ( Please Specify )'
//     ]
//   },
//   'Share Folder': {
//     'Category': [
//       'Access to share folder',
//       'New share folder creation',
//       'Other ( Please Specify )'
//     ]
//   },
//   'Software and licenses': {
//     'Category': [
//       'Activation Google Workspace',
//       'Activation Microsoft 365',
//       'Install non-standard applications',
//       'Other ( Please Specify )'
//     ]
//   },
//   'USB Access Request': {
//     'Category': [
//       'Tempory Access',
//       'Other ( Please Specify )'
//     ]
//   },
//   'VPN Access': {
//     'Category': [
//       'Access to VPN IT Service Request',
//       'Other ( Please Specify )'
//     ]
//   },
// };



const Form = () => {
  const[cat1,setCat1] = useState("");
  const [cat2,setCat2] = useState("");
  const [cat3,setCat3] = useState("");
  
  const handlefirstselect = (e) => {
  setCat1(e.target.value);
       

  }

  const handleSecondselect = (e) => {
   setCat2(e.target.value);
  }

   const handlethirdselect = (e) => {
       setCat3(e.target.value);
       console.log(cat3);
   }


  const selector1 = ["IT Incident","IT Service Request"];

  const selector2 = {
   "IT Incident" : ["Laptop/Desktop Issue","Hardware Issue","Email MailBox Issue","File / Share Folder Issue","Outlook / Webmail Issue","VPN Issues","Internet Issue"],
   "IT Service Request" : ['CCTV Requests','Data Backup and Retrieval','Email Requests','EndPoint Request','External Accessories ', 'Company - IT Infra Setup' , 'Internet Access', 'IT Internal - Activities' ,'Password Request' , 'Share Folder' , 'Software and licenses' , 'USB Access Request' ,'VPN Access'   ]
  }
  const selector3 = {
   
   "Laptop/Desktop Issue" : [
       'Laptop/Desktop Issue',
       'Account Lock',
       'Other ( Please Specify )',
       'Performance Issue',
       'Unable To Login'
     ],
     "Hardware Issue" : [
       'Power Issue',
       'Unable To Start'
     ],
       "Email MailBox Issue" : [
           'Mail Searching / Indexing',
           'MailBox Full / Archive',
           'Other ( Please Specify )',
           'Unable To Send / Receive'
       ],
       "File / Share Folder Issue" : [
           'Copy / Paste Issue',
           'Drive Not Accessible',
           'Files Missing',
           'Other ( Please Specify )'
       ],
       "Outlook / Webmail Issue" : [
           'Outlook Is Slow',
           'Unable To Access Webmail',
           'Outlook PST',
           'Other ( Please Specify )'
       ],
       "VPN Issues" : [
           'Unable To Connect',
           'VPN Connected but Unable to access IT Service Request',
           'Disconnecting Frequently',
           'Other ( Please Specify )'
       ],
       "Internet Issue" : [
           'Website Blocked / Not Working',
           'Internet Is Slow',
           'No Internet',
           'Other ( Please Specify )'
       ],
       "CCTV Requests" : [
           'CCTV Requests'
       ],
       "Data Backup and Retrieval" : [
           'Data Backup and Retrieval'
       ],
       "Email Requests" : [
           'Email Requests'
       ],
       "EndPoint Request" : [
           'EndPoint Request'
       ],
       "External Accessories " : [
           'External Accessories '
       ],
       "Company - IT Infra Setup" : [
           'Company - IT Infra Setup'
       ],
       "Internet Access" : [
           'Internet Access'
       ],
       "IT Internal - Activities" : [
           'IT Internal - Activities'
       ],
       "Password Request" : [
           'Passwor Request'
       ],
       "Share Folder" : [
           'Share Folder'
       ],
       "Software and licenses" : [
           'Software and licenses'
       ],
       "USB Access Request" : [
           'USB Access Request'
       ],
       "VPN Access" : [
           'VPN Access'
       ]
    

  }   
  return (
    <form className="form-container">
      <h2>Submit a HelpDesk Ticket</h2>
      <p>
        Create a ticket below. An IT Support Executive will respond promptly
        to your issue. You may also send tickets directly to
      </p>
      <div className="contact-info">
        <img src={mail} alt="Mail Icon" className="icon" />
        <a href='mailto:thelpdesk@redientsecurity.org' style={{marginRight:'20px',textDecoration:'none',color:'black'}} >ithelpdesk@redientsecurity.org</a>
        <img src={phone} alt="Phone Icon" className="icon" />
        +91 14135-42286
      </div>
      <hr className="divider" />
      <div className="input-group">
        <input type="text" placeholder="Full Name" className="input" required />
        <input type="email" placeholder="Email" className="input" required />
      </div>
      <div className="input-group">
        <input type="text" placeholder="Address" className="input" required />
        <input type="tel" placeholder="+91 XXXXX-XXXXX" className="input" required />
        
      </div>
      <div className="input-group">
        <select className="select-input" required>
          <option value="" disabled selected>
            City/Location
          </option>
	<option value="--Select--">--Select--</option>
	<option value="">Agartala - 1st Floor, Ava Market, Motor Stand, Shivnagar, Agartala, Tripura - 799001</option>
	<option value="">Agra - Sanjay Place</option>
	<option value="">Ahmedabad - Shail Complex, Off C G Road, Navrangpura</option>
	<option value="">Ahmedabad - Shop No. 210,231, 2nd Floor, Maruti Plaza, Krishna Nagar, Ahmedabad-382345</option>
	<option value="">Allahabad - Civil Lines</option>
	<option value="">Amritsar - Batala Road</option>
	<option value="">Asansol - Ground Floor, Surya Sen Park, 170 G.T. Road (West) Asansol, West Bengal -713304 </option>
	<option value="">Aurangabad - 1st Floor,Mulay Tapadia Complex,Kailash Nagar,Mondha, Maharashtra-431001 </option>
	<option value="">Bangalore - Grand Majestic Gandhinagar</option>
	<option value="">Bangalore - South End Circle</option>
	<option value="">Berhampore (Murshidabad) - Ground Floor,Ward No.4, 127/33, Maharaja Nanda Kr Road, West Bengal</option>
	<option value="">Bhagalpur - Ground Floor, Gayatri Super Market, Surkhikal Road, Tilkamanjhi Chowk,Bihar</option>
	<option value="">Bhopal - Aashima Mall</option>
	<option value="">Bhopal - MP Nagar Zone 1</option>
	<option value="">Bongaigaon - Ground Floor, Barpara Road, Near LIC Zonal Office, Assam</option>
	<option value="">BHUBANESWAR - A/167, Ground Floor Saheed Nagar, Bhubaneswar -751007 Odhisa</option>
	<option value="">Chandigarh - Sector 17A</option>
	<option value="">Chennai - Ten Square Mall</option>
	<option value="">Cooch Behar - Ground Floor, Ward BSNL Administrative Building, Near SagarDighi, West Bengal - 736101</option>
	<option value="">Daman - Dabhel Checkpost</option>
	<option value="">Dehradun - GMS Road</option>
	<option value="">Dehradun - Race Course</option>
	<option value="">Delhi - Akshardham Metro Station</option>
	<option value="">Delhi - Dhaula Kuan Metro Station</option>
	<option value="">Delhi - Inderlok Metro Station</option>
	<option value="">Delhi- Kashmere gate, Ground floor, Eldeco junction metro station Gate no.01. </option>
	<option value="">Delhi - Mohan Estate</option>
	<option value="">Devangere - Vishal Arcade, P B Road</option>
	<option value="">Dhanbad - Near SLNT College</option>
	<option value="">Dhanbad - Near Big Bazaar, Saraidhela</option>
	<option value="">Dharwad - Mandi Plaza, Shop-7, Ground Floor, Haliyal Road, Dharwad 580008</option>
	<option value="">Dibrugarh- Hotel Indusurya Complex, RKB Path, New Market Road, Dibrugarh, Assam- 786001</option>
	<option value="">East Godavari - 2nd Floor, Bank of Baroda Building, Opp. Tripura Sundari Temple, Temple Street, Kakinada</option>
	<option value="">Ghaziabad - Thapar Plaza, NH-91, Panchvati Colony</option>
	<option value="">Gonda - Near SBI, Civil Lines, Ambedkar Chowk</option>
	<option value="">Gorakhpur - Second Floor, Above UBI Bank, Dubey Complex,U.P.-273001</option>
	<option value="">Greater Noida (West) - Lower Ground Floor, Galaxy Blue Sapphire Plaza, Sector-4, U.P. - 201301</option>
	<option value="">Guwahati -  Sureka Square</option>
	<option value="">Hisar - Metropolis Mall</option>
	<option value="">Howrah - Webel IT Park</option>
	<option value="">Hubli - Club Road</option>
	<option value="">Hyderabad - Madhapur</option>
	<option value="">Hyderabad - First Floor, Moosarambagh, Saroor Nagar, Hyderabad - 500036</option>
	<option value="">Indore - Race Course Road</option>
	<option value="">Itanagar- WAll Apartment, Doni Colony, Itanagar, Arunachal Pradesh-791111</option>
	<option value="">Jaipur - Civil Lines Metro (Orbit Mall)</option>
	<option value="">Jammu - Chanderbagha</option>
	<option value="">Jamshedpur - First Floor, House No. 8B, Old Puruliya Road, Near Skyline Tower, Mango Chowk, East Singhbhum, Jharkhand - 831012</option>
	<option value="">Jodhpur - Court Road</option>
	<option value="">Kanpur- 1st Floor, Clyde House, Mall Road</option>
	<option value="">Kochi - Chakos Chambers, Palarivattom</option>
	<option value="">Kolkata - Salt Lake Sector V</option>
	<option value="">Kota- Aakash Mall Airport Area</option>
	<option value="">Lakhimpur - Ground Floor, Ashrof Ali Path, Khelmati, Ward No. 14, North Lakhimpur, Assam</option>
	<option value="">Lucknow - Ratan Square</option>
	<option value="">Ludhiana - SCO 16, Sector 32A Main market, Chandigarh Road, Ludhiana Punjab 141010</option>
	<option value="">Madurai - First Floor, P K Towers, KK Nagar, Opposite District Court, Tamil Nadu</option>
	<option value="">Malda - Ground Floor, BSNL Building main telephone exchange, English Bazar, Malda, West Bengal 732101</option>
	<option value="">Mangalore - Crystal Are, Ist Floor, Balmatta Road, Mangalore</option>
	<option value="">Meerut - Football Chowk, Delhi Road </option>
	<option value="">Moradabad - A-17, Ground Floor, Commercial Building, Near ICICI Bank, Gandhi Nagar, Rampur Road, Moradabad 244001</option>
	<option value="">Mumbai - NIBR 1 Aerocity, Saki Naka</option>
	<option value="">Muzaffarpur - First Floor, Purani Sabji Mandi, Main Road, Opp. Central Bank of India, Muzaffarpur 842001</option>
	<option value="">Mysore -  CCK Complex Vijaynagar</option>
	<option value="">Mysore - Saraswathipuram</option>
	<option value="">Nadia - Webel IT Park</option>
	<option value="">Nagpur - Sadiqabad</option>
	<option value="">Nashik - Office No. 101, First Floor, Janki Plaza, above Janki Hardware, Dwarka Chawk, Nashik, Maharashtra - 422011 </option>
	<option value="">Navi Mumbai - 1st Floor F103/104, Tower no.4, Vashi station, International Infotech Park, Sector 30, Vashi, Navi Mumbai, Maharashtra - 400703 </option>
	<option value="">Patna - New Dak Bungalow Road</option>
	<option value="">Purnea - Ground Floor, Sri Nayak Hotel Campus, Jail Chowk, Purnea (Bihar) -854301 </option>
	<option value="">Raipur - Pandri Bus Stand</option>
	<option value="">Ranchi -  Kantatoli Chowk</option>
	<option value="">Ranchi - Galaxia Mall, Ratu Road</option>
	<option value="">Saharanpur - Ground Floor, Panth Vihar, Opp. Sahiram Hospital, Hasanpur Chowk, Delhi Road, Saharanpur 247001</option>
	<option value="">Shillong - Rynjah Market</option>
	<option value="">Shimla - ISBT Tutikandi</option>
	<option value="">Silchar - Birendra Bhavan, Vivekananda Road</option>
	<option value="">Siliguri - Rajani Bagan HC Road</option>
	<option value="">Silvassa - Amli Industrial Area</option>
	<option value="">Srinagar - Ground Floor, NB House, Commercial Complex, Exchange Road, Lal Chowk, Srinagar, Jammu and Kashmir - 190001</option>
	<option value="">Surat - Galaxy Enclave,Pal Gam</option>
	<option value="">Surat - La Citadel Complex, Opposite Ganga Hotel, Near CNG Pump</option>
	<option value="">Thane- 2nd Floor, Lake City Mall, Kapurbawadi Junction, Balkum Road</option>
	<option value="">Varanasi - Neelambar, D 63/7, C4, Mahmoorganj</option>
	<option value="">Vijayawada - Labbipet</option>
	<option value="">Vishakhapatnam - Grand Palace Dwaraka Naga</option>
	<option value="">Warangal- Kandakatla Gateway Hanamkonda</option>

</select>
        <select name="option1" id="" className="select-input"  onChange={handlefirstselect}>
        <option value="" disabled selected>
            Grouping
          </option>
            {selector1?.map((cat)=> <option value={cat}>{cat}</option>)}
          </select>
      </div>


      <div className="input-group">
      <select name="option2" id=""  className="select-input"  onChange={handleSecondselect}>
      <option value="" disabled selected>
            Category
          </option>
            {selector2[cat1]?.map((cat)=> <option value={cat}>{cat}</option>)}
            </select>
            <select name="option3" id="" className="select-input" onChange={handlethirdselect}>
            <option value="" disabled selected>
            Sub-Category
          </option>
             {selector3[cat2]?.map((cat)=> <option value={cat}>{cat}</option>)}
        </select>
      </div>

      <form className="input-group">
        

        <textarea placeholder='Enter detail description of your issue here...' style={{width:'100%',height:'100px',padding:'10px'}} />

      </form>

      
      <div>
        <button className="btn btn-warning" style={{backgroundColor:'#f48c06',color:'white',padding:'9px',marginRight:'20px',borderRadius:'10px',fontSize:'19px',width:'14%'}} >Submit</button>
        <button className="btn btn-warning" style={{backgroundColor:'#f48c06',color:'white',padding:'9px',borderRadius:'10px',fontSize:'19px',width:'14%'}} >Reset</button>
      </div>
    </form>
  );
};

export default Form;

import React from 'react'
import imge from './logo.png'

import { IoMdLogIn } from "react-icons/io";


const Navbar = () => {
  return (
    <div style={{width:'100%',display:'flex',justifyContent:'space-between',padding:'10px'}} >
        <img src={imge} style={{height:'60px',marginLeft:'20px',marginTop:'-5px'}} alt="Ironhack logo" />


        <div style={{display:'flex',justifyContent:'end', alignItems:'center',paddingRight:'30px',paddingTop:'10px',gap:'20px',paddingBottom:'7px' }} >
            <div style={{fontSize:'20px'}} >
                Support(24/7): +1 123 456 7890  
            </div>
            <div  style={{fontSize:'20px',display:'flex',gap:'5px',alignItems:'center'}}>
                <IoMdLogIn />
                <span>LOGIN</span>
            </div>
        </div>


    </div>
  )
}

export default Navbar
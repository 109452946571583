import React from 'react'
import Navbar from './Components/Navbar'
import HomePage from './Components/HomePage'
import NavHead from './Components/NavHead'
import Footer from './Components/Footer'

const App = () => {
  return (
    <div style={{backgroundColor:'#f4f1de',minHeight:'97vh',margin:'-8px',padding:'0px',overflowX:'hidden'}}  >
      {/* <NavHead/> */}
      <Navbar/>
      <HomePage/>
      <Footer/>
    </div>
  )
}

export default App
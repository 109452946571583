import React from 'react';
import './Footer.css'

const Footer = () => {
  return (
    <div style={{ backgroundColor: '#bd1f36', position: 'fixed', top: '828px', display: 'flex', width: '96.5%', height: '50px', justifyContent: 'space-between', alignItems: 'center', padding: '0 30px' }}>
<a href='mailto:thelpdesk@redientsecurity.org' style={{textDecoration:'none',color:'white'}} >
    For Support, please mail us at: <span className="support-link" >ithelpdesk@redientsecurity.org</span>
</a>

 <div style={{ color: 'white' }}>Powered By Redient Security..</div>
    </div>
  );
}

export default Footer;

import React,{useState} from 'react'
import Form from './Form'
import Check from './Check'

const HomePage = () => {
  return (
    <div>
        {/* 0077b6 */}
        <div style={{backgroundColor:'#0077b6',height:'200px',width:'400px',margin:'auto',fontSize:'35px',width:'100%',height:'250px',textAlign:'center',paddingTop:'35px',color:'white'}} > Welcome to Redient IT HelpDesk </div>
        {/* <Check /> */}
        <Form />
    </div>
  )
}

export default HomePage
